import React, { Component } from 'react';
import { Button, Form, Header, Icon, Message, Modal } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { login, logout } from '../../fetcher';
import _ from "lodash"
import { UserContext } from '../../context';

class Error401 extends Component {
	state = {
		open: true,
		formValue: {},
		isLoading: false, 
		formStatus: {},
		lookPass: false,
	}

	/**
	 * Handle Func
	 */
	async handleEnter(data, event){
		if(_.isEqual(event.keyCode, 13) && !data.disabled){
			await this.handleSubmit()
		}
	}
	handleLogout = async () => {
		await logout();
		await this.context.checkUser();
	}
	handleSubmit = async () => {
		const { formValue } = this.state
		const { sekolah } = this.context
		this.setState({isLoading: true});
		const response = await login({...formValue, sekolah_kode: sekolah.kode});
		if (response.status_code === 200) {
			return window.location.reload()
		} else {
			const formStatus = { status: 'error', props: {} }
			if (response.message === "Username and Password combination is invalid") {
				formStatus.props = { error: true, size: 'tiny', header: 'Login Gagal', content: 'Kombinasi username dan password yang Anda masukan tidak sesuai' };		
			}
			return this.setState({formStatus, isLoading: false});
		}
	}
	handleChange = (e, data) => {
		const { name, value } = data;
		this.setState({formStatus: {}, formValue: _.assign({}, this.state.formValue, { [name]: value }) });
	}
	/**
	 * Handle React Cycle
	 */
	render() {
		const { isLoading, formValue, formStatus, lookPass } = this.state
		const { username, password } = formValue;

		return (
			<Modal open={this.state.open} size='mini'>
				<Header>Sesi Anda sudah habis</Header>
				<Modal.Content>
					<div>
						<p>
							Anda tidak aktif selama lebih 4 jam. Demi keamanan, sistem mengakhiri sesi Anda. <br /><br />
							Silahkan login kembali untuk melanjutkan <br /><br />
						</p>
						<Form>
							<Form.Field required>
								<label>Username</label>
								<Form.Input disabled={isLoading} onKeyUp={this.handleEnter.bind(this, {disabled: (isLoading)})} name="username" value={username || ""} onChange={this.handleChange} placeholder='Masukkan username unik Anda' />
							</Form.Field>
							<Form.Field required>
								<label>Password</label>
								<Form.Input disabled={isLoading} onKeyUp={this.handleEnter.bind(this, {disabled: (isLoading)})} name="password" value={password || ""} onChange={this.handleChange} icon={<Icon name={lookPass ? "eye" :"eye slash"} link onClick={() => this.setState({lookPass: !lookPass})}/>} type={lookPass ? "text" :"password"} placeholder='Masukkan password / kata sandi akun Anda' />
							</Form.Field>
						</Form>
						{!_.isEmpty(formStatus) && <div style={{marginTop: "1em"}}><Message {...formStatus.props} /></div>}
					</div>
				</Modal.Content>
				<Modal.Actions>
					<div style={{display: "flex", justifyContent: "space-between"}}>
						<Link to="/login"><Button onClick={this.handleLogout}>
							Keluar / Log Out
						</Button></Link>
						<Button color="green" onClick={this.handleSubmit}>
							Masuk <Icon name='arrow right' /> 
						</Button>
					</div>
				</Modal.Actions>
			</Modal>
		)
	}
}

Error401.contextType = UserContext
export default Error401;